import React, { useContext, useEffect } from 'react';
import { AppProps } from 'next/app';
import { TariffsModal } from 'components/TariffsModal/TariffsModal';
import { PORTAL_ROOT_ID } from 'components/Portal/Portal';
import { ApiDataContextProvider } from 'store/ApiDataContext/ApiDataContext';
import { NextPage } from 'next';
import { Device, LayoutContext } from 'store/LayoutContext';
import { UserProfile } from '@sberdevices/vc-contracts';

import { UserContextProvider } from '../../store/UserContext';
import { fetchAnalytics } from '../../utils/fetchAnalytics';
import { InvoiceContextProvider } from '../../store/InvoiceContext';
import { TermApproveModal } from '../TermApproveModal/TermApproveModal';
import { canUseDOM } from '../../utils/canUseDom';
import { PaymentStatusModal } from '../PaymentStatusModal/PaymentStatusModal';
import { WebpushContextProvider } from '../../store/WebpushContext';
import { WebpushApproveModal } from '../WebpushApproveModal/WebpushApproveModal';

interface CustomAppProps extends AppProps {
    pageProps: {
        user?: UserProfile | null;
    };
}

export const Root: NextPage<CustomAppProps> = ({ Component, pageProps }) => {
    const { device } = useContext(LayoutContext);

    useEffect(() => {
        fetchAnalytics().then((analyticsInstance) => {
            let platform: string | null;

            switch (device) {
                case Device.Mobile:
                    platform = 'Web Mobile';
                    break;
                case Device.Desktop:
                    platform = 'Web Desktop';
                    break;
                default:
                    platform = null;
            }

            analyticsInstance.setPlatform(platform);
        });
    }, [device]);

    return (
        <UserContextProvider user={pageProps.user}>
            <ApiDataContextProvider>
                <InvoiceContextProvider>
                    <WebpushContextProvider>
                        <Component {...(pageProps as any)} />
                        <TariffsModal />
                        <TermApproveModal />
                        <WebpushApproveModal />
                        {canUseDOM() && <div id={PORTAL_ROOT_ID} />}
                        <PaymentStatusModal />
                    </WebpushContextProvider>
                </InvoiceContextProvider>
            </ApiDataContextProvider>
        </UserContextProvider>
    );
};

import { UserProfile } from '@sberdevices/vc-contracts'
import cookies from 'js-cookie'
import { NextRouter, useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { auth } from '../api/auth'
import { getFeatures, setUsePayments } from '../api/user'
import { apiConfig } from '../configs/api'
import { RouteAuthRequirement, routesConfig, routesInfo } from '../configs/routes'
import { LOCALE_COOKIE } from '../const/cookie'
import { WORKFLOW_NAME } from '../const/environment'
import { useBetaTestQueryParam } from '../hooks/useBetaTestQueryParam'
import { trackSberIdLogin } from '../pagesSpecific/main/utils/mainAnalytics'
import { SberIdAuthState } from '../typings/auth'
import { Routes } from '../typings/config'
import { fetchAnalytics, fetchGa } from '../utils/fetchAnalytics'
import { baseAxiosInstance } from '../utils/interceptor'

import { getCurrentLocale } from '../utils/locale'
import { setToken } from '../utils/token'

const checkPath = (router: NextRouter, user: UserProfile): void => {
    const locale = getCurrentLocale(router, user);

    cookies.set(LOCALE_COOKIE, locale);

    if (!user && routesInfo[router.pathname as keyof Routes]?.auth === RouteAuthRequirement.Require) {
        router.push(routesConfig.login, undefined, { locale });

        return;
    }

    if (user && routesInfo[router.pathname as keyof Routes]?.auth === RouteAuthRequirement.Unauthorized) {
        router.push(routesConfig.main, undefined, { locale });

        return;
    }

    if (router.locale !== locale) {
        router.push(router.asPath, undefined, { locale });
    }
};

enum AuthStatus {
    Pending,
    Ready,
    Init,
}

const env = process.env.WORKFLOW_NAME as WORKFLOW_NAME;

export interface UserContextModel {
    user?: UserProfile | null;
    features: string[];
    setUser(user: UserProfile): void;
    refreshUser(): Promise<void>;
    updateFeatures(): Promise<void>;
}

export const initialContext: UserContextModel = {
    user: undefined,
    features: [],
    setUser: () => {},
    refreshUser: Promise.resolve,
    updateFeatures: Promise.resolve,
};

export const UserContext = React.createContext<UserContextModel>(initialContext);

interface UserContextProviderProps {
    user?: UserProfile | null;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ user: authorizedUser, children }) => {
    const router = useRouter();
    const [user, setUser] = useState<UserProfile | null | undefined>(authorizedUser);
    const currentLocale = getCurrentLocale(router, user);
    const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Init);
    const [features, setFeatures] = useState<string[]>([]);

    const setUserWithAnalytics = useCallback(async (newUser: UserProfile) => {
        fetchAnalytics().then((analyticsInstance) => analyticsInstance.setUserId(String(newUser.tracking_id)));

        if (window.clarity) {
            window.clarity('set', 'tracking_id', `${newUser.tracking_id}`);
        }

        // Артур так попросил
        if (newUser.is_staff) {
            fetchAnalytics().then((analyticsInstance) =>
                analyticsInstance.setUserProperties({
                    is_staff: true,
                }),
            );
        }

        if (env === WORKFLOW_NAME.Release) {
            fetchGa().then((analytics) =>
                analytics.push({
                    eventAction: 'auth',
                    properties: {
                        auth: 'auth',
                    },
                }),
            );
        }

        // TODO починить после релиза
        // if (newUser && authStorage.get('user_created')) {
        //     trackActivateAccountSuccess(newUser.tracking_id);
        // }

        trackSberIdLogin();
        setUser(newUser);
    }, []);

    const updateFeatures = useCallback(async () => setFeatures(await getFeatures()), []);

    const refreshUser = useCallback(() => {
        return auth().then(async (newUser) => {
            console.log('newUser', newUser)
            if (newUser) {
                await setUserWithAnalytics(newUser as UserProfile);
            }

            checkPath(router, newUser as UserProfile);
            await updateFeatures();
            console.log('authEnd')
            setAuthStatus(AuthStatus.Ready);
        });
    }, [router, setUserWithAnalytics, updateFeatures]);

    const userContextState = useMemo(
        () => ({ user, features, setUser: setUserWithAnalytics, refreshUser, updateFeatures }),
        [refreshUser, setUserWithAnalytics, features, updateFeatures, user],
    );

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            const hasState = urlParams.has('state');
            const hasCode = urlParams.has('code');
            const hasOption1 = urlParams.has('option1');

            if (hasState && hasCode && hasOption1) {
                const state = urlParams.get('state')
                const code = urlParams.get('code')
                const option1 = urlParams.get('option1')
                console.log('deb-auth-got-state-and-code-and-option1', state, code, option1);

                urlParams.delete('state');
                urlParams.delete('code');
                urlParams.delete('option1');
                window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

                baseAxiosInstance
                    .post<SberIdAuthState>(apiConfig.sberIdAuth2, { state, code, option1 }, { params: { state, code, option1 } })
                    .then((response) => {
                        const jwtToken = response.data.token
                        console.log('deb-auth-jwt-token', response.data, response.data.token)
                        setToken(jwtToken)
                    });
            }
        }
    }, []);

    useEffect(() => {
        // Тут был странный код, хз что произойдет
        if (authStatus === AuthStatus.Init) {
            if (user != null) {
                setUserWithAnalytics(user)
                    .then(updateFeatures)
                    .then(() => setAuthStatus(AuthStatus.Ready));
            } else {
                updateFeatures().then(() => setAuthStatus(AuthStatus.Ready));
                setAuthStatus(AuthStatus.Pending);
                refreshUser();
            }
        }
    }, [setUserWithAnalytics, authStatus, router, user, refreshUser, updateFeatures]);

    useEffect(() => {
        fetchAnalytics().then((analyticsInstance) => {
            analyticsInstance.setLanguage(currentLocale);
        });
    }, [currentLocale]);

    useBetaTestQueryParam(user, setUsePayments);

    // return <UserContext.Provider value={userContextState}>{children}</UserContext.Provider>
    return authStatus === AuthStatus.Ready ? (
        <UserContext.Provider value={userContextState}>{children}</UserContext.Provider>
    ) : null;
};

import { routesConfig } from 'configs/routes';
import { TokenObtainSliding, TokenSlidingApi, TokenRefreshSliding, UserProfile } from '@sberdevices/vc-contracts';
import { AxiosError } from 'axios';
import { getToken, resetToken, setToken } from 'utils/token';

import { canUseDOM } from '../utils/canUseDom';
import { authStorage, sceneLS } from '../utils/lsStorages';
import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { basePath, configuration } from '../configs/base';
import { HttpStatus } from '../const/httpStatus';
import { SberIdAuthState, SocialAuthProvider } from '../typings/auth';
import { LocalStorageKeys } from '../const/localStorage';
import { fetchAnalytics, fetchGa } from '../utils/fetchAnalytics';
import { getQueryParams } from '../hooks/useQuery';

import { getUser } from './user';
import { signInBySberIdAuthState } from './sberIdAuth';
import { socialAuth } from './socialAuth';

export const axiosInstance = new TokenSlidingApi(configuration, basePath, baseAxiosInstance);

interface SocialAuthState {
    provider: SocialAuthProvider;
}

export function getSlidingToken(login: string, pass: string): Promise<string> {
    return axiosInstance
        .tokenSlidingCreate({ username: login, password: pass })
        .then((response) => response.data)
        .then((response) => response.token);
}

async function sberIdAuth(state: SberIdAuthState, code: string | null): Promise<UserProfile | undefined> {
    if (!state || !code || new Date(state.epxIso).getTime() < Date.now()) {
        return;
    }

    const response = await signInBySberIdAuthState(state, code);

    setToken(response.token);

    return getUser();
}

enum AuthProcessorProvider {
    Jwt,
    SberId,
    Social,
}

type AuthProcessor = () => Promise<UserProfile | undefined | void>;

const authProcessors: Record<AuthProcessorProvider, AuthProcessor> = {
    [AuthProcessorProvider.Jwt]: async () => {
        console.log('authProcessors.Jwt', getToken());
        if (getToken()) {
            return getUser();
        }
    },
    [AuthProcessorProvider.SberId]: async () => {
        const sberIdAuthState = authStorage.get<SberIdAuthState>(LocalStorageKeys.SberIdAuthState);
        const { code } = getQueryParams();

        // TODO: change this part, when sber auth v2 will be done
        return sberIdAuth(sberIdAuthState, code);
    },
    [AuthProcessorProvider.Social]: async () => {
        const state = authStorage.get<SocialAuthState>(LocalStorageKeys.SocialAuthState);
        const { code } = getQueryParams();
        const analyticsProviderValues: Record<SocialAuthProvider, string> = {
            [SocialAuthProvider.Vk]: 'vk',
            [SocialAuthProvider.Google]: 'google',
            [SocialAuthProvider.Apple]: 'apple',
            [SocialAuthProvider.Facebook]: 'facebook',
        };

        authStorage.clear();

        if (!code || !state?.provider) {
            return;
        }

        const { token, user_created } = await socialAuth(state.provider, code);

        setToken(token);

        const user = await getUser();

        if (user_created) {
            fetchGa().then((analytics) => {
                analytics.setUserId(String(user.tracking_id));

                analytics.push({
                    eventAction: 'registration',
                    properties: {
                        auth: 'auth',
                        reg_type: state.provider,
                    },
                });
            });

            // TODO починить после релиза
            // fetchAnalytics().then((analyticsInstance) =>
            //     analyticsInstance.submit({
            //         eventType: 'User:Signup',
            //         eventAction: 'entryType',
            //         eventCategory: 'account',
            //         eventProperty: analyticsProviderValues[state.provider],
            //         userId: user.id,
            //     }),
            // );
        }

        return user;
    },
};

export async function auth(): Promise<UserProfile | undefined> {
    const providers = (Object.keys(authProcessors) as any) as AuthProcessorProvider[];

    try {
        for (const provider of providers) {
            console.log('authLoop')
            // eslint-disable-next-line no-await-in-loop
            const user = await authProcessors[provider]();
            console.log('user', user)
            if (user) {
                authStorage.clear();

                return user;
            }
        }

        authStorage.clear();
        sceneLS.clear();
    } catch (e) {
        resetToken();
        authStorage.clear();
        sceneLS.clear();
    }
}

export async function signIn(login: string, pass: string): Promise<UserProfile> {
    const token = await getSlidingToken(login, pass);

    setToken(token);

    return getUser();
}

export function logOut(target: string = routesConfig.login): void {
    if (!canUseDOM()) return;

    resetToken();
    authStorage.clear();
    sceneLS.clear();
    window.location.href = target;
    fetchAnalytics().then((analyticsInstance) => analyticsInstance.setUserId(null));
}

export function resetPasswordRequest(email: string): Promise<void> {
    return baseAxiosInstance.post(apiConfig.resetPassword, { email });
}

export function activateAccount(uid: string, token: string, password: string): Promise<string> {
    return baseAxiosInstance
        .post<TokenRefreshSliding>(`${apiConfig.activate}${uid}/${token}/`, { new_password: password })
        .then((response) => response.data.token);
}

export function unsubscribe(token: string): Promise<string> {
    return baseAxiosInstance
        .post<TokenRefreshSliding>(apiConfig.unsubscribe, { token })
        .then((response) => response.data.token);
}

export function checkActivateTokenExpired(uid: string, token: string): Promise<boolean> {
    return baseAxiosInstance
        .post<TokenObtainSliding>(`${apiConfig.activate}${uid}/${token}/`)
        .then(() => false)
        .catch((error: AxiosError) => error.response?.status === HttpStatus.Gone);
}

export function refreshActivateToken(uid: string): Promise<void> {
    return baseAxiosInstance.post(`${apiConfig.reactivate}${uid}/`);
}

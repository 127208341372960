/* istanbul ignore file */
import { Config } from '../typings/config';

export const appConfig: Config = {
    basename: '/',
    devHost: 'https://localhost',
    prHost: 'https://stage.visper.tech',
    stageHost: 'https://stage.visper.tech',
    prodHost: 'https://visper.tech',
    apiPath: '/api',
    poolingTimeout: 5000,
    tokenRefreshSafeTime: 900000,
};
